@font-face {
  font-family: "NotoSans";
  src: url("../fonts/Notosansdisplayregular.woff2") format("woff2"),
      url("../fonts/Notosansdisplayregular.woff") format("woff"),
      url("../fonts/Notosansdisplayregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/Notosansdisplaysemibold.woff2") format("woff2"),
      url("../fonts/Notosansdisplaysemibold.woff") format("woff"),
      url("../fonts/Notosansdisplaysemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/Notosansdisplaybold.woff2") format("woff2"),
      url("../fonts/Notosansdisplaybold.woff") format("woff"),
      url("../fonts/Notosansdisplaybold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
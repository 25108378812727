@media screen and (max-width: 991px) {
  .banner {
    &__img {
      margin-right: 0;
    }
  }

  .about {
    &__images {
      justify-content: center;
      margin-right: 0;
    }
    &__img {
      display: none;
    }
  }

  footer {
    .footer {
      &__logo {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  header {
    .header {
      &__wrap {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(24deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-24deg);
              transform-origin: left;
            }
          }
        }
      }
      &__logo {
        height: 20px;
      }
      &__nav {
        position: absolute;
        top: -20px;
        left: 50px;
        background-color: #fff;
        padding: 30px 50px;
        //width: 200px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
          top: 10px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          align-items: flex-start;

          li {
            margin-bottom: 15px;
            margin-left: 0;
            font-size: 18px;
            font-weight: 600;
            text-transform: none;
          }
          a, span {
            color: #000;
            
            &:after {
              background-color: #000;
            }
          }
        }
      }
    }
  }

  main {
    .single & {
      flex-direction: column;
    }
  }

  aside {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding-top: 15px;
    border-width: 1px 0 0 0;
  }

  .banner {
    &__img {
      display: none;
    }
    &__btn {
      margin-bottom: 20px;
    }
  }
  .catalog {
    &__item {
      width: 100%;
    }
  }
  .about {
    &__images {
      justify-content: space-between;

      img {
        width: 48%;
        height: auto;

        &:first-child {
          margin-right: 4%;
        }
      }
    }
  }

  footer {
    background-image: none;
    padding-top: 0;
    margin-top: 0;

    .footer {
      &__wrap {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .banner {
    &__btn {
      font-size: 20px;
    }
  }
  .content {
    blockquote {
      &.special {
        padding: 20px;

        &:before {
          display: none;
        }
      }
    }
    table {
      font-size: 16px;

      td {
        padding: 10px 5px;
      }
    }
  }
  .about {
    &__images {
      flex-direction: column;

      img {
        width: 100%;
        margin-bottom: 30px;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/Notosansdisplayregular.woff2") format("woff2"), url("../fonts/Notosansdisplayregular.woff") format("woff"), url("../fonts/Notosansdisplayregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/Notosansdisplaysemibold.woff2") format("woff2"), url("../fonts/Notosansdisplaysemibold.woff") format("woff"), url("../fonts/Notosansdisplaysemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/Notosansdisplaybold.woff2") format("woff2"), url("../fonts/Notosansdisplaybold.woff") format("woff"), url("../fonts/Notosansdisplaybold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  overflow-x: hidden;
}
body {
  min-height: 100vh;
  font-size: 18px;
  font-weight: 400;
  font-family: NotoSans, Arial, sans-serif;
  line-height: 1.4;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 10px;
}
header {
  position: relative;
  background-color: rgba(2, 2, 2, 0.7);
  margin-bottom: 25px;
}
.single header {
  background-color: rgba(2, 2, 2, 0.84);
}
header .header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 15px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 6px;
}
header .header__burger span:nth-child(3) {
  top: 12px;
}
header .header__logo {
  height: 30px;
  width: auto;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}
header .header__nav li {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}
header .header__nav li.current a:after,
header .header__nav li.current span:after {
  opacity: 1;
  visibility: visible;
  bottom: -2px;
}
header .header__nav a,
header .header__nav span {
  position: relative;
}
header .header__nav a:after,
header .header__nav span:after {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 6px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
header .header__nav a:hover:after,
header .header__nav span:hover:after {
  opacity: 1;
  visibility: visible;
  bottom: -2px;
}
main {
  padding-bottom: 30px;
}
.single main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 30px;
}
article {
  flex: 1;
}
aside {
  width: 275px;
  margin-left: 20px;
  margin-bottom: 35px;
  padding-left: 15px;
  border-color: #B9B9B9;
  border-style: solid;
  border-width: 0 0 0 1px;
}
aside .sidebar-block {
  margin-bottom: 20px;
}
aside .sidebar-block__title {
  display: block;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #FEC107;
  margin-bottom: 35px;
  padding: 20px;
  background-color: rgba(2, 2, 2, 0.85);
}
aside .sidebar-block__list {
  list-style: none;
  font-size: 18px;
}
aside .sidebar-block__list li {
  margin-bottom: 12px;
}
aside .sidebar-block__list li.current {
  text-decoration: underline;
}
aside .sidebar-block__list a:hover {
  text-decoration: underline;
}
.banner {
  background-image: url('../img/bg-header.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  margin: -105px 0 25px;
  padding: 105px 0 50px;
}
.banner__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.banner__img {
  margin: -105px 85px -25px -15px;
}
.banner__img img {
  height: 100%;
  width: auto;
}
.banner__text {
  flex: 1;
  color: #fff;
}
.banner__title {
  color: #FEC107;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 50px;
}
.banner__list {
  font-weight: 600;
  list-style: none;
}
.banner__list li {
  position: relative;
  margin-bottom: 30px;
  padding-left: 50px;
}
.banner__list li:before {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  background-image: url('../img/list-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.banner__btn {
  display: inline-block;
  position: relative;
  background-color: #FEC107;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-radius: 15px;
  margin-top: 15px;
  padding: 20px 20px 20px 60px;
}
.banner__btn:before {
  display: block;
  content: '';
  position: absolute;
  width: 27px;
  height: 27px;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../img/button-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.banner__btn:hover {
  background-color: rgba(254, 193, 7, 0.8);
}
.catalog {
  margin-bottom: 20px;
}
.catalog__title {
  display: block;
  color: #FEC107;
  text-transform: uppercase;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 35px;
}
.catalog__title span {
  display: inline-block;
  background-color: rgba(2, 2, 2, 0.7);
  padding: 10px 50px;
}
.catalog__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.catalog__item {
  width: 48%;
  height: 300px;
  position: relative;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.catalog__item .item__title {
  position: absolute;
  width: 270px;
  right: 0;
  bottom: 35px;
  background-color: #2A292F;
  text-align: center;
  font-weight: 600;
  color: #FEC107;
  padding: 12px 26px 15px;
}
.about {
  background-image: url('../img/bg-about.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 115px 0;
  margin-bottom: 25px;
}
.about__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.about__text {
  flex: 1;
}
.about__title {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FEC107;
  margin-bottom: 50px;
}
.about__list {
  font-weight: 600;
  color: #fff;
  list-style: none;
}
.about__list li {
  position: relative;
  margin-bottom: 24px;
  padding-left: 50px;
}
.about__list li:before {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../img/list-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.about__images {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 90px;
  margin-right: -210px;
}
.about__images img {
  width: 300px;
  height: 300px;
}
.about__images img:first-child {
  margin-right: 50px;
}
.about__img {
  width: 400px;
  height: 600px;
  margin-left: 150px;
  border-radius: 30px;
  overflow: hidden;
}
.about__img img {
  height: 100%;
  width: auto;
}
.content {
  margin-bottom: 35px;
}
.content > * {
  margin-bottom: 15px;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  text-align: center;
  font-weight: 600;
}
.content h1 {
  font-size: 24px;
}
.content h2 {
  font-size: 20px;
}
.content h3 {
  font-size: 18px;
}
.content ul,
.content ol {
  padding-left: 15px;
}
.content blockquote {
  position: relative;
  background-color: #FEC107;
  padding: 25px 25px 25px 80px;
}
.content blockquote:before {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../img/quote-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.content blockquote.special {
  padding: 45px 30px 45px 125px;
  font-weight: 600;
  color: #fff;
  background-image: url('../img/bg-blockquote.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}
.content blockquote.special:before {
  background-image: url('../img/list-icon.svg');
  left: 50px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.content table {
  max-width: 100%;
  margin: 20px auto;
  border-collapse: collapse;
}
.content table th,
.content table td {
  border: 1px solid #B9B9B9;
  padding: 10px;
  text-align: center;
}
.content table th {
  background-color: rgba(0, 0, 0, 0.05);
}
footer {
  background-color: rgba(2, 2, 2, 0.85);
  background-image: url('../img/bg-footer.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  margin-top: 20px;
  padding-top: 100px;
}
footer .footer__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
}
footer .footer__logo {
  height: 30px;
  width: auto;
  padding-right: 5px;
}
footer .footer__logo img {
  height: 100%;
  width: auto;
}
footer .footer__block {
  max-width: 215px;
  color: #fff;
  padding: 0 5px;
}
footer .footer__block .block__title {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
footer .footer__block .block__list {
  list-style: none;
  margin-bottom: 20px;
}
footer .footer__block .block__list li {
  position: relative;
  margin-bottom: 8px;
  font-size: 13px;
}
footer .footer__block .block__list li.current a,
footer .footer__block .block__list li.current span {
  text-decoration: underline;
}
footer .footer__block .block__list a:hover,
footer .footer__block .block__list span:hover {
  text-decoration: underline;
}
footer .footer__block .block__list--bigger li {
  font-size: 18px;
}
footer .footer__social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}
footer .footer__social img {
  margin-right: 15px;
}
footer .footer__social img:last-child {
  margin-right: 0;
}
footer .footer__copyright {
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding: 10px;
}
@media screen and (max-width: 991px) {
  .banner__img {
    margin-right: 0;
  }
  .about__images {
    justify-content: center;
    margin-right: 0;
  }
  .about__img {
    display: none;
  }
  footer .footer__logo {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  header .header__wrap {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(24deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-24deg);
    transform-origin: left;
  }
  header .header__logo {
    height: 20px;
  }
  header .header__nav {
    position: absolute;
    top: -20px;
    left: 50px;
    background-color: #fff;
    padding: 30px 50px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  header .header__nav.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    align-items: flex-start;
  }
  header .header__nav ul li {
    margin-bottom: 15px;
    margin-left: 0;
    font-size: 18px;
    font-weight: 600;
    text-transform: none;
  }
  header .header__nav ul a,
  header .header__nav ul span {
    color: #000;
  }
  header .header__nav ul a:after,
  header .header__nav ul span:after {
    background-color: #000;
  }
  .single main {
    flex-direction: column;
  }
  aside {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    padding-top: 15px;
    border-width: 1px 0 0 0;
  }
  .banner__img {
    display: none;
  }
  .banner__btn {
    margin-bottom: 20px;
  }
  .catalog__item {
    width: 100%;
  }
  .about__images {
    justify-content: space-between;
  }
  .about__images img {
    width: 48%;
    height: auto;
  }
  .about__images img:first-child {
    margin-right: 4%;
  }
  footer {
    background-image: none;
    padding-top: 0;
    margin-top: 0;
  }
  footer .footer__wrap {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .banner__btn {
    font-size: 20px;
  }
  .content blockquote.special {
    padding: 20px;
  }
  .content blockquote.special:before {
    display: none;
  }
  .content table {
    font-size: 16px;
  }
  .content table td {
    padding: 10px 5px;
  }
  .about__images {
    flex-direction: column;
  }
  .about__images img {
    width: 100%;
    margin-bottom: 30px;
  }
  .about__images img:first-child {
    margin-right: 0;
  }
}

@import "fonts";

@yellow: #FEC107;

.black_rgba(@percent) {
  background-color: rgba(2, 2, 2, @percent);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  font-size: 18px;
  font-weight: 400;
  font-family: NotoSans, Arial, sans-serif;
  line-height: 1.4;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 10px;
}

header {
  position: relative;
  .black_rgba(0.7);
  margin-bottom: 25px;

  .single & {
    .black_rgba(0.84);
  }

  .header {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 15px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #fff;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 6px;
        }
        &:nth-child(3) {
          top: 12px;
        }
      }
    }
    &__logo {
      height: 30px;
      width: auto;

      img {
        height: 100%;
        width: auto;
      }
    }
    &__nav {
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
      }
      li {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;

        &.current {
          a, span {
            &:after {
              opacity: 1;
              visibility: visible;
              bottom: -2px;
            }
          }
        }
      }
      a, span {
        position: relative;

        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          left: 0;
          bottom: 6px;
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
          transition: all .3s ease;
        }
        &:hover {
          &:after {
            opacity: 1;
            visibility: visible;
            bottom: -2px;
          }
        }
      }
    }
  }
}

main {
  padding-bottom: 30px;

  .single & {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 30px;
  }
}
article {
  flex: 1;
}
aside {
  width: 275px;
  margin-left: 20px;
  margin-bottom: 35px;
  padding-left: 15px;
  border-color: #B9B9B9;
  border-style: solid;
  border-width: 0 0 0 1px;

  .sidebar-block {
    margin-bottom: 20px;

    &__title {
      display: block;
      width: 100%;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      color: @yellow;
      margin-bottom: 35px;
      padding: 20px;
      .black_rgba(0.85);
    }
    &__list {
      list-style: none;
      font-size: 18px;

      li {
        margin-bottom: 12px;
        
        &.current {
          text-decoration: underline;
        }
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.banner {
  background-image: url('../img/bg-header.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  margin: -105px 0 25px;
  padding: 105px 0 50px;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__img {
    margin: -105px 85px -25px -15px;

    img {
      height: 100%;
      width: auto;
    }
  }
  &__text {
    flex: 1;
    color: #fff;
  }
  &__title {
    color: @yellow;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  &__list {
    font-weight: 600;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 30px;
      padding-left: 50px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0;
        left: 0;
        background-image: url('../img/list-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  &__btn {
    display: inline-block;
    position: relative;
    background-color: @yellow;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    border-radius: 15px;
    margin-top: 15px;
    padding: 20px 20px 20px 60px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 27px;
      height: 27px;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../img/button-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &:hover {
      background-color: rgba(254, 193, 7, 0.8);
    }
  }
}
.catalog {
  margin-bottom: 20px;

  &__title {
    display: block;
    color: @yellow;
    text-transform: uppercase;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 35px;

    span {
      display: inline-block;
      .black_rgba(0.7);
      padding: 10px 50px;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__item {
    width: 48%;
    height: 300px;
    position: relative;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .item {
      &__title {
        position: absolute;
        width: 270px;
        right: 0;
        bottom: 35px;
        background-color: #2A292F;
        text-align: center;
        font-weight: 600;
        color: @yellow;
        padding: 12px 26px 15px;
      }
    }
  }
}
.about {
  background-image: url('../img/bg-about.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 115px 0;
  margin-bottom: 25px;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__text {
    flex: 1;
  }
  &__title {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: @yellow;
    margin-bottom: 50px;
  }
  &__list {
    font-weight: 600;
    color: #fff;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 24px;
      padding-left: 50px;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url('../img/list-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  &__images {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 90px;
    margin-right: -210px;

    img {
      width: 300px;
      height: 300px;

      &:first-child {
        margin-right: 50px;
      }
    }
  }
  &__img {
    width: 400px;
    height: 600px;
    margin-left: 150px;
    border-radius: 30px;
    overflow: hidden;

    img {
      height: 100%;
      width: auto;
    }
  }
}
.content {
  margin-bottom: 35px;

  & > * {
    margin-bottom: 15px;
  }
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    font-weight: 600;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  ul, ol {
    padding-left: 15px;
  }
  blockquote {
    position: relative;
    background-color: @yellow;
    padding: 25px 25px 25px 80px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../img/quote-icon.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    
    &.special {
      padding: 45px 30px 45px 125px;
      font-weight: 600;
      color: #fff;
      background-image: url('../img/bg-blockquote.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-blend-mode: multiply;
      
      &:before {
        background-image: url('../img/list-icon.svg');
        left: 50px;
      }
    }
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  table {
    max-width: 100%;
    margin: 20px auto;
    border-collapse: collapse;

    th, td {
      border: 1px solid #B9B9B9;
      padding: 10px;
      text-align: center;
    }
    th {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

footer {
  .black_rgba(0.85);
  background-image: url('../img/bg-footer.png');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  margin-top: 20px;
  padding-top: 100px;

  .footer {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      padding-bottom: 20px;
    }
    &__logo {
      height: 30px;
      width: auto;
      padding-right: 5px;

      img {
        height: 100%;
        width: auto;
      }
    }
    &__block {
      max-width: 215px;
      color: #fff;
      padding: 0 5px;

      .block {
        &__title {
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        &__list {
          list-style: none;
          margin-bottom: 20px;

          li {
            position: relative;
            margin-bottom: 8px;
            font-size: 13px;

            &.current {
              a, span {
                text-decoration: underline;
              }
            }
          }
          a, span {
            &:hover {
              text-decoration: underline;
            }
          }

          &--bigger {
            li {
              font-size: 18px;
            }
          }
        }
      }
    }
    &__social {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 20px;

      img {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__copyright {
      font-size: 12px;
      color: #fff;
      text-align: center;
      padding: 10px;
    }
  }
}

@import "media";